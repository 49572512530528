// Bootstrap overrides
//
// Copy variables from `_variables.scss` to this file to override default values
// without modifying source files.

// Quickly modify global styling by enabling or disabling optional features.

$enable-flex:            false;
$grid-columns:           12;
$grid-gutter-width:      100px;
$btn-border-radius:      0px;
$btn-primary-bg:         #131212;
$breadcrumb-bg:          #fff;
